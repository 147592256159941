import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { UpdateProfileDto, ProfileDto } from './../dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'
import { AuthStore } from '@api/store/auth'
import { HttpHeaders } from '@angular/common/http'

@Injectable()
export class ProfileService {
  constructor(
    private authStore: AuthStore,
    private httpService: HttpService,
    private fileService: FileService
    ) {
  }

  async current() {
    const user = await this.httpService.get<ProfileDto>('/v1/profile/admin')
    const authUser = _.clone(user)

    this.authStore.update({ user: authUser })

    return user
  }

  async update({ fullName, phoneNumber }: UpdateProfileDto) {
    return await this.httpService.put<Boolean>('/v1/profile', { fullName, phoneNumber })
  }

  
  async uploadPhoto(file: File) {
    const formData = new FormData()
    const headers = new HttpHeaders()
      
    formData.append('file', file)
    return await this.httpService.patch<Boolean>('/v1/profile/photo', formData, headers)
  }
}
