import { Injectable } from "@angular/core"

import { CreateAdvertisementDto, FindAdvertisementDto, PageResultDto, AdvertisementDetailDto, AdvertisementDto, UpdateAdvertisementDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class AdvertisementService {
  constructor(
    private httpService: HttpService){
  }

  async find({ limit, offset, searchText }: FindAdvertisementDto) {
    return await this.httpService.get<PageResultDto<AdvertisementDto>>('/merchant/v1/announcements', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<AdvertisementDetailDto>(`/merchant/v1/announcements/${id}`)
  }

  async create(createAdvertisementDto: CreateAdvertisementDto) {
    return await this.httpService.post<AdvertisementDto>('/merchant/v1/announcements', createAdvertisementDto)
  }

  async update(id: string, updateAdvertisementDto: UpdateAdvertisementDto) {
    return await this.httpService.put<boolean>(`/merchant/v1/announcements/${id}`, updateAdvertisementDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/merchant/v1/announcements/${id}`)
  }

  async updateThumbnail(id: string, fileId: string) {
    return await this.httpService.put<boolean>(`/merchant/v1/announcements/${id}/thumbnail`, { fileId })
  }

  async sendNotification(id: string) {
    return await this.httpService.post<boolean>(`/merchant/v1/announcements/${id}/notification`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/merchant/v1/announcements/${id}/enable`)
    }

    return await this.httpService.put<Boolean>(`/merchant/v1/announcements/${id}/disable`)
  }
}