import { BaseDto } from "./base.dto"
import { assign } from 'lodash'
import { TranslationDto } from "./translation.dto"
export class ChoiceGroupDto extends BaseDto {
    name: TranslationDto
    isRequire: boolean = false
    selectType: string
    minSelect: number
    maxSelect: number
    items: ChoiceGroupItemsDto[]

    static create() {
        return assign<ChoiceGroupDto>({
            id: null,
            name: { en: '', km: '' },
            selectType: 'single',
            minSelect: 1,
            maxSelect: 1,
            isRequire: false,
            items: []
        })
    }
}
export class EditChoiceGroupDto extends BaseDto {
    name: TranslationDto
    selectType: string
    minSelect: number
    maxSelect: number
    isRequire: boolean = false
    items: ChoiceGroupItemsDto[]

    static create({ id, name, isRequire, items }: ChoiceGroupDto) {
        return assign<ChoiceGroupDto>({
            id: id || null,
            selectType: 'single',
            minSelect: 1,
            maxSelect: 1,
            name: name || { en: '', km: '' },
            isRequire: isRequire || false,
            items: items || []
        })
    }
}

export class FindChoiceGroupItems extends ChoiceGroupDto { }

export class UpdateChoiceItemsDto extends BaseDto {
    name: TranslationDto
    selectType: string
    minSelect: number
    maxSelect: number
    isRequire: boolean = false
    items: ChoiceGroupItemsDto[]

    static create({ id, name, minSelect, maxSelect, selectType, isRequire, items }: UpdateChoiceItemsDto) {
        return assign<UpdateChoiceItemsDto>({
            id: id || null,
            minSelect,
            maxSelect,
            selectType,
            name: name || { en: '', km: '' },
            isRequire: isRequire || false,
            items: items || []
        })
    }
}

export class UpdateChoiceGroupDto extends BaseDto {
    name: TranslationDto
    selectType: string
    minSelect: number
    maxSelect: number
    isRequire: boolean = false
}

export class ChoiceGroupItemsDto extends BaseDto {
    name?: TranslationDto
    price?: number

    static create() {
        return assign<ChoiceGroupItemsDto>({
            id: null,
            name: { en: '', km: '' },
            price: 0
        })
    }
}

export class AddMoreItemsDto extends BaseDto {
    name?: TranslationDto
    price?: number

    static create() {
        return assign<AddMoreItemsDto>({
            id:  null,
            name: { en: '', km: '' },
            price:  0
        })
    }
}

export class DeleteChoiceItems {
    choiceId: string
    choiceItemId: string
}

export class UpdateChoiceItemDto {
    name?: TranslationDto
    price?: number
}

export class CreateChoiceItemDto {
    name?: TranslationDto
    price?: number
}