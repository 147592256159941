import { BaseDto } from './base.dto'
import { DateOfBirthDto } from './date-of-birth.dto'
import { FileDto } from './file.dto'
import { TenantDto } from './tenant.dto'
import * as _ from 'lodash';

export class UserDto extends BaseDto {
  fullName: string
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  gender: string
  dateOfBirth: string
  membershipCard: MembershipDto
  enabled: boolean
  photo: FileDto
  loyalPoint: number
  roleNames: string[]
  tenant: TenantDto
  tenantId: string
  deletedAt: Date

  static create(userDto?: UserDto) {
    const user = new UserDto()
    user.fullName = user?.fullName ?? ''
    user.email = user?.email ?? ''
    user.firstName = user?.firstName ?? ''
    user.lastName = user?.lastName ?? ''
    user.phoneNumber = user?.phoneNumber ?? ''
    user.gender = user?.gender ?? ''
    user.dateOfBirth = user?.dateOfBirth ?? null
    user.enabled = user?.enabled ?? null
    user.photo = user?.photo ?? null
    user.membershipCard = user?.membershipCard ?? MembershipDto.create()
    user.roleNames = user?.roleNames ?? []
    user.tenant = user?.tenant ?? TenantDto.create()
    user.tenantId = user?.tenantId ?? null
    user.deletedAt = user?.deletedAt ?? null
    user.createdAt = user?.createdAt ?? null
    user.id = user?.id ?? null

    return user
  }
}

export enum RoleNames {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  TENANT = 'TENANT',
  MASTER_TENANT = 'MASTER_TENANT',
  ACCOUNT = 'ACCOUNT',
  SALE = 'SALE',
  COUNTER = 'COUNTER',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE'
}

export class CreateUserDto {
  fullName: string
  email: string
  password: string
  roleName: string
  photoId?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export class MembershipDto {
  card_no: string
  status: string
  club_code: string
  account_code: string
  scheme_code: string
  contact_code: string
  last_valid_date: string

  static create(){
    return _.assign<MembershipDto>({
      cardNumber: null,
      status: null,
      accountCode: null,
      cardType: null,
      clubCode: null
    })
  }
}