import _ from 'lodash'
import * as moment from 'moment'

import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'
import { ProductDiscountDto } from './product-discount.dto'

export class PromotionDto extends BaseDto {
  title: TranslationDto
  thumbnail: FileDto
  startedDate: String
  endedDate: String
  enabled: boolean
}

export class PromotionDetailDto extends BaseDto {
  title?: TranslationDto
  shortDescription?: TranslationDto
  link?: string
  discount?: ProductDiscountDto
  content?: TranslationDto
  thumbnail?: FileDto
  image?: FileDto
  isPublished?: boolean
  mobileImage?: FileDto
  startedDate?: string
  endedDate?: string
  publishedNotification?: boolean
  enabled?: boolean

  static create(promotion?: PromotionDetailDto) {
    const { id, title, enabled, thumbnail, startedDate, link, endedDate, mobileImage, image, content, shortDescription, isPublished, discount } = promotion || {}
    return _.assign<PromotionDetailDto>({
      title: title || TranslationDto.create(),
      content: content || TranslationDto.create(),
      shortDescription: shortDescription || TranslationDto.create(),
      discount: discount || ProductDiscountDto.create(),
      enabled: enabled,
      thumbnail: thumbnail || null,
      mobileImage: mobileImage || null,
      isPublished: isPublished || null,
      image: image || null,
      link: link || null,
      thumbnailId: thumbnail ? thumbnail.id : null,
      imageId: image ? image.id : null,
      mobileImageId: mobileImage ? mobileImage.id : null,
      startDate: moment(startedDate).toDate() || null,
      endDate: moment(endedDate).toDate() || null
    })
  }
}

export class CreatePromotionDto {
  title: TranslationDto
  content: TranslationDto
  shortDescription: TranslationDto
  thumbnailId: string
  link?: string
  isPublished?: boolean
  imageId: string
  // mobileImageId: string
  startedDate: string
  endedDate: string
}

export class UpdatePromotionDto {
  title?: TranslationDto
  content?: TranslationDto
  shortDescription?: TranslationDto
  thumbnailId?: string
  isPublished?: boolean
  discountId?: string
  link?: string
  imageId?: string
  mobileImageId?: string
  enabled?: boolean
  startedDate?: string
  endedDate?: string
}

export class FindPromotionDto extends PagingDto {
  searchText?: string
}