import { Injectable } from '@angular/core'

import { CategoryDto, CreateTenantCouponProductSpecificDto, FindTenantCouponDto, PageResultDto, TenantCouponDto, TenantCouponHistoryDto } from '../dto'

import { HttpService } from './http.service'
import { FindProductDto, ProductDto } from '../dto/product.dto';
import { FindTenantCategoryDto, TenantCategoryDto } from '../dto/tenant-category.dto';
import { BrandDto, FindBrandDto } from '../dto/brand.dto';
import { CreateOrUpdateTenantCouponUserDto, CreateTenantCouponBrand, CreateTenantCouponDto, CreateTenantCouponProduct, exportCouponDto, exportCouponHistoryDto, FindTenantCouponProductDto, FindTenantCouponUserDto, TenantCouponBirthdayUserDto, TenantCouponUserDto } from '../dto/tenant-coupon.dto';

@Injectable()
export class TenantCouponService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindTenantCouponDto) {
    return await this.httpService.get<PageResultDto<TenantCouponDto>>(`/merchant/v1/coupons`, findVoucherDto)
  }

  async findProducts(findProductDto: FindTenantCouponProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/merchant/v1/coupons/product`, findProductDto)
  }

  async findCategories(findCategoryDto: FindTenantCategoryDto) {
    return await this.httpService.get<PageResultDto<CategoryDto>>(`/merchant/v1/coupons/category`, findCategoryDto)
  }

  async findBrands(findBrandDto: FindBrandDto) {
    return await this.httpService.get<PageResultDto<BrandDto>>(`/merchant/v1/coupons/brand`, findBrandDto)
  }

  async findHistory(pagingDto: FindTenantCouponDto) {
    return await this.httpService.get<PageResultDto<TenantCouponHistoryDto>>(`/merchant/v1/coupons/history`, pagingDto)
  }

  async findCouponProducts(couponId: string, findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/merchant/v1/coupons/${couponId}/product`, findProductDto)
  }

  async findCouponCategories(couponId: string, findCategoryDto: FindTenantCategoryDto) {
    return await this.httpService.get<PageResultDto<TenantCategoryDto>>(`/merchant/v1/coupons/${couponId}/category`, findCategoryDto)
  }

  async findCouponBrands(couponId: string, findBrandDto: FindBrandDto) {
    return await this.httpService.get<PageResultDto<BrandDto>>(`/merchant/v1/coupons/${couponId}/brand`, findBrandDto)
  }

  async addCouponProduct(couponId: string, tenantCouponDto: CreateTenantCouponProduct) {
    return await this.httpService.post<Boolean>(`/merchant/v1/coupons/${couponId}/product`, tenantCouponDto)
  }

  async addAllCouponProduct(couponId: string) {
    return await this.httpService.put<Boolean>(`/merchant/v1/coupons/${couponId}/product/addAll`)
  }

  async findOrCreateCouponUser(couponId: string, tenantCouponDto: CreateOrUpdateTenantCouponUserDto) {
    return await this.httpService.post<Boolean>(`/merchant/v1/coupons/${couponId}/product`, tenantCouponDto)
  }
  
  async findCouponUser(userDto: FindTenantCouponUserDto) {
    return await this.httpService.get<PageResultDto<TenantCouponUserDto>>(`/merchant/v1/coupons/user`, userDto)
  }

  async findBirthdayCouponUser(userDto: FindTenantCouponUserDto) {
    return await this.httpService.get<PageResultDto<TenantCouponBirthdayUserDto>>(`/merchant/v1/coupons/birthday-user`, userDto)
  }


  async addCouponBrand(couponId: string, tenantCouponDto: CreateTenantCouponBrand) {
    return await this.httpService.post<Boolean>(`/merchant/v1/coupons/${couponId}/brand`, tenantCouponDto)
  }

  async addCouponCategory(couponId: string, tenantCouponDto: CreateTenantCouponProduct) {
    return await this.httpService.post<Boolean>(`/merchant/v1/coupons/${couponId}/category`, tenantCouponDto)
  }

  async deleteCouponProduct(couponId: string, productId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/coupons/${couponId}/product/${productId}`)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantCouponDto>(`/merchant/v1/coupons/${id}`)
  }

  async exportCode(id: string) {
    return await this.httpService.get<any>(`/merchant/v1/coupons/${id}/export-code`)
  }

  async exportExcel(exportCouponDto: exportCouponDto) {
    return await this.httpService.get<any>(`/merchant/v1/coupons/export-report`, exportCouponDto)
  }

  async exportExcelBirthday(exportCouponDto: exportCouponDto) {
    return await this.httpService.get<any>(`/merchant/v1/coupons/export-birthday`, exportCouponDto)
  }

  async exportExcelHistory(exportHistoryDto: exportCouponHistoryDto) {
    return await this.httpService.get<any>(`/merchant/v1/coupons/history/export-report`, exportHistoryDto)
  }

  async create(createVoucherDto: CreateTenantCouponDto) {
    return await this.httpService.post<TenantCouponDto>('/merchant/v1/coupons', createVoucherDto)
  }

  async createSpecific(createVoucherDto: CreateTenantCouponProductSpecificDto) {
    return await this.httpService.post<TenantCouponDto>('/merchant/v1/coupons/specific', createVoucherDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/coupons/${id}`)
  }
}
