import { BaseDto } from './base.dto'
import { TranslationDto } from './translation.dto'

export class ShippingProviderDataDto {
  id: string
  name: TranslationDto
  enabled: boolean
}

export enum ShippingProvider {
  STANDARD_DELIVERY = 'Standard Delivery',
  EXPRESS_DELIVERY = 'Express Delivery'
}

export class ShippingZoneDto extends BaseDto {
  city: string
  enabled: boolean
  geometry: any
  provinceCode: string
  cost: number
  defaultGeometry: any
  provider: ShippingProviderDataDto[]
  rates: ShippingRateDto[]
  lat: number
  lng: number
}

export class ShippingProviderDto extends BaseDto {
  name: TranslationDto
  cost: number
  sortOrder: number

  static create() {
    return new ShippingProviderDto()
  }
}

export class UpdateShippingProviderDto {
  name: TranslationDto
}

export class ShippingRateDto extends BaseDto {
  public min: number
  public providerCost: number
  public customerCost: number
  public shippingZoneId: string
  public shippingProviderId: string
  public zone: ShippingZoneDto
  public provider: ShippingProviderDto
  public enabled: boolean

  static create() {
    return new ShippingRateDto()
  }
}

export class UpdateShippingZoneDto {
  geometry?: any
  cost?: number
}

export class UpdateShippingRateDto {
  cost: number
}