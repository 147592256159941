import { CategoryDto, ProductDto, TenantDto } from "."


export class FindDashboardDto {
  startDate?: Date
  endDate?: Date
}

export class DashboardTenantOverviewDto {
  totalTenant: number
  totalProduct: number
}

export class DashboardInventoryDto {
  totalInventory: number
  totalLowInventory: number
}

export class DashboardAnalyticsDto {
  totalViewers: number
  totalMaleViewers: number
  totalFemaleViewers: number
  totalUndefinedViewers: number
  categories: CategoryDto[]
  tenants: TenantDto[]
}

export class MerchantDashboardAnalyticsDto {
  totalViewers: number
  totalMaleViewers: number
  totalFemaleViewers: number
  totalUndefinedViewers: number
  categories: CategoryDto[]
  products: ProductDto[]
}

export class MerchantFilterDateRange {
  startDate?: Date
  endDate?: Date
}

export class DashboardOverviewDto {
  numberOfRegisteredUserToday: number
  numberOfRegisteredUserWeek: number
  numberOfRegisteredUserCustom: number

  numberOfNewOrderToday: number
  numberOfNewOrderWeek: number
  numberOfNewOrderCustom: number

  numberOfAcceptedOrderToday: number
  numberOfAcceptedOrderWeek: number
  numberOfAcceptedOrderCustom: number

  numberOfCompletedOrderToday: number
  numberOfCompletedOrderWeek: number
  numberOfCompletedOrderCustom: number

  numberOfRejectedToday: number
  numberOfRejectedWeek: number
  numberOfRejectedCustom: number

  numberOfCancelledOrderToday: number
  numberOfCancelledOrderWeek: number
  numberOfCancelledOrderCustom: number

  static create() {
    return new DashboardOverviewDto()
  }
}
