import { Injectable } from '@angular/core'

import { PageResultDto, TenantVoucherCampaignDetailDto } from '../dto'

import { HttpService } from './http.service'
import { UpdateTenantVoucherCampaignDto } from '../dto/tenant-voucher.dto'
import { PagingDto } from '../dto/paging.dto';

@Injectable()
export class TenantVoucherCampaignService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto){
    return await this.httpService.get<PageResultDto<TenantVoucherCampaignDetailDto>>(`/merchant/v1/voucher-campaign`, pagingDto)
  }

  async setEnabled(voucherCampaignType: string, boolean){
    if(boolean){
      return await this.httpService.put<Boolean>(`/merchant/v1/voucher-campaign/setEnabled/${voucherCampaignType}`)
    }else{
      return await this.httpService.put<Boolean>(`/merchant/v1/voucher-campaign/setDisabled/${voucherCampaignType}`)
    }
  }

  async findOne(type: string) {
    return await this.httpService.get<TenantVoucherCampaignDetailDto>(`/merchant/v1/voucher-campaign/${type}`)
  }

  async update(type: string, updateVoucherCampaignDto: UpdateTenantVoucherCampaignDto) {
    return await this.httpService.post<boolean>(`/merchant/v1/voucher-campaign/${type}`, updateVoucherCampaignDto)
  }

}
