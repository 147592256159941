import _ from 'lodash'
import * as moment from 'moment'

import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'

export class AdvertisementDto extends BaseDto {
  shortDescription: TranslationDto
  title: TranslationDto
  thumbnail: FileDto
  enabled: boolean
}

export class AdvertisementDetailDto extends BaseDto {
  title?: TranslationDto
  shortDescription?: TranslationDto
  content?: TranslationDto
  thumbnail?: FileDto
  image?: FileDto
  mobileImage?: FileDto
  publishedNotification?: boolean
  enabled?: boolean

  static create({ id, shortDescription, title, enabled, thumbnail, mobileImage, image, content }: AdvertisementDetailDto) {
    return _.assign<AdvertisementDetailDto>({
      title: title || TranslationDto.create(),
      shortDescription: shortDescription || TranslationDto.create(),
      content: content || TranslationDto.create(),
      enabled: enabled,
      thumbnail: thumbnail || null,
      mobileImage: mobileImage || null,
      image: image || null,
      thumbnailId: thumbnail ? thumbnail.id : null,
      imageId: image ? image.id : null,
      mobileImageId: mobileImage ? mobileImage.id : null
    })
  }
}

export class CreateAdvertisementDto {
  title: TranslationDto
  shortDescription: TranslationDto
  content: TranslationDto
  thumbnailId: string
  imageId: string
  mobileImageId?: string
}

export class UpdateAdvertisementDto {
  title?: TranslationDto
  shortDescription: TranslationDto
  content?: TranslationDto
  thumbnailId?: string
  imageId?: string
  mobileImageId?: string
  enabled?: boolean
}

export class FindAdvertisementDto extends PagingDto {
  searchText?: string
}