import { Injectable } from '@angular/core'

import { CreateProductDiscountDto, FindProductDiscountDto, FindProductDto, PageResultDto, ProductDiscountDto, ProductDto, UpdateProductDiscountDto } from '@api/dto'

import { HttpService } from './http.service'
import { CreateProductDiscountItemsDto, ProductDiscountItemsDto } from '../dto/product.dto'

@Injectable()
export class ProductDiscountService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: FindProductDiscountDto) {
    return await this.httpService.get<PageResultDto<ProductDiscountDto>>(`/merchant/v1/product-discount`, pagingDto)
  }

  async findProducts(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/merchant/v1/product-discount/products`, findProductDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<ProductDiscountDto>(`/merchant/v1/product-discount/${id}`)
  }

  async create(createDiscountProductCollectionDto: CreateProductDiscountDto) {
    return await this.httpService.post<ProductDiscountDto>(`/merchant/v1/product-discount`, createDiscountProductCollectionDto)
  }

  async update(id: string, updateDiscountProductCollectionDto: UpdateProductDiscountDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/product-discount/${id}`, updateDiscountProductCollectionDto)
  }

  async updateItems(id: string, productItems: CreateProductDiscountItemsDto[]) {
    return await this.httpService.post<Boolean>(`/merchant/v1/product-discount/items`, { productDiscountId: id, productItems })
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/product-discount/${id}`)
  }

  async deleteItem(id: string, productId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/product-discount/${id}/items/${productId}`)
  }

  async importProduct(file: File) {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.patch<ProductDiscountItemsDto[]>('/merchant/v1/product-discount/import-products', formData, { headers })
  }

  async setEnabled(id: string, enabled: boolean){
    if(enabled){
      return await this.httpService.put<Boolean>(`/merchant/v1/product-discount/${id}/enabled`)
    }else{
      return await this.httpService.put<Boolean>(`/merchant/v1/product-discount/${id}/disabled`)
    }
  }
}