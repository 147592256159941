import * as _ from 'lodash'
import { BaseDto } from './base.dto'

export class AllowPhoneNumberDto extends BaseDto {
  otpCode: string
  phoneNumber?: string

  static create(otp?: AllowPhoneNumberDto) {
    const result = new AllowPhoneNumberDto()

    result.id = otp?.id ?? null,
    result.otpCode = otp?.otpCode ?? null,
    result.phoneNumber =  otp?.phoneNumber ?? null
    
    return result
  }
}