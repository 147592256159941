export const TYPES = [
  // {
  //   title: 'common.digital',
  //   value: 'digital'
  // },
  {
    title: 'common.physical',
    value: 'physical'
  }
]

export const STOCKS = [
  {
    title: 'common.instock',
    value: 'in-stock'
  },
  // {
  //   title: 'common.preorder',
  //   value: 'pre-order'
  // }
]

export const DISCOUNT_TYPES = [
  {
    title: 'common.fixed',
    value: 'fixed'
  },
  {
    title: 'common.percentage',
    value: 'percentage'
  }
]

export const CKEDITOR_CONFIG = {
  height: '450',
  toolbar: [
    ['Styles','Format','Font','FontSize'],
    '/',
    ['Bold','Italic','Underline','StrikeThrough','-','Undo','Redo','-','Cut','Copy','Paste','Find','Replace','-','Outdent','Indent','-','Print'],
    '/',
    ['NumberedList','BulletedList','-','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
    ['Table','-','Link','Smiley','TextColor','BGColor','Source']
 ]
}

export const NUMBER_OF_IMAGES = 9
export const NUMBER_OF_VIDEOS = 1
export const NUMBER_OF_SPECS_IMAGES = 9

export const DEFAULT_OPTIONS = [
  {
    name: 'common.productOptions.color',
    value: 'Color',
    terms: ['color', 'ពណ៌', 'colour']
  },
  {
    name: 'common.productOptions.size',
    value: 'Size',
    terms: ['size', 'ទំហំ']
  },
]

export enum LightningDealStatus {
  UPCOMING = 'upcoming', 
  APPROVED = 'approved', 
  EXPIRED = 'expired',
  REJECTED = 'rejected',
  ON_GOING = 'on-going'
}

export enum BadgeType {
  BEST_SELLING = 'best_selling',
  BUY_ONE_FREE_ONE = 'buy_one_free_one',
  NEW_IN = 'new_in',
  HOT_DEAL = 'hot_deal',
  CLEARANCE = 'clearance',
  RESTOCK = 'restock'
}