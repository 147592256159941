export { AuthService } from './auth.service'
export { HttpService } from './http.service'
export { LocalStorageService } from './local-storage.service'
export { FileService } from './file.service'
export { ProfileService } from './profile.service'
export { SettingService } from './setting.service'
export { CategoryService } from './category.service'
export { ProductService } from './product.service'
export { OrderService } from './order.service'
export { ProductDiscountService } from './product-discount.service'
export { InventoryService } from './inventory.service'
export { DashboardService } from './dashboard.service'
export { WindowRef } from './window-ref.service'
export { TenantService } from './tenant.service'
export { ProductReviewService } from './product-review.service'
export { FloorMapService } from './floor-map.service'
export { TenantVoucherCampaignService } from './tenant-voucher-campaign.service'
export { TenantCouponService } from './tenant-coupon.service'
export { ShippingService } from './shipping.service'
export { MerChantReportService } from './merchant-report.service'
export { ChoiceGroupService } from './choice.service'
export { DeliveryFeeService } from './delivery-fee.service'
export { AdvertisementService } from './advertisement.service'

export { BannerService } from './banner.service'
export { pageService } from './page.service'
export { AllowPhoneNumberService } from './allow-phone-number.service'
export { PromotionService } from './promotion.service'