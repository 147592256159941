import { BaseDto } from "./base.dto"
import * as _ from 'lodash'

export class DeliveryFeeDto extends BaseDto {
  minDistance: number
  maxDistance: number
  price: number

  static create() {
    return new DeliveryFeeDto()
  }
}

export class CreateDeliveryFeeDto {
  maxDistance: number
  price: number
  vehicleTypeId?: string
}

export class UpdateDeliveryFeeDto {
  maxDistance?: number
  price?: number
  vehicleTypeId?: string
}