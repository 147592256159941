import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, PromotionDto } from '../dto'
import { AdminFeaturedBrandDto, AdminFindFeaturedBrandDto } from '@api/dto/featured-brand.dto'


@Injectable()
export class AdminFeaturedBrandService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: AdminFindFeaturedBrandDto) {
    return await this.httpService.get<PageResultDto<AdminFeaturedBrandDto>>('/merchant/v1/brands/feature', { limit, offset })
  }

  async create({ brandId }: AdminFeaturedBrandDto) {
    return await this.httpService.post<Boolean>('/merchant/v1/brands/feature', { brandId })
  }

  async sortOrder(brandIds: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/brands/feature/reorder`, { brandIds })
  }

  async delete(brandId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/brands/feature/${brandId}`)
  }

  // async create(createPromotion: PromotionDto) {
  //   return await this.httpService.post<PromotionDto>(`/v1/merchant/promotions`, createPromotion)
  // }

  // async find(pagingDto: findPromotionDto) {
  //   return await this.httpService.get<PageResultDto<PromotionDto>>('/v1/merchant/promotions', pagingDto)
  // }

  // async findOne(promotionId: string) {
  //   return this.httpService.get<PromotionDto>(`/v1/merchant/promotions/${promotionId}`)
  // }

  // async update(promotionId: string, updatePromotion: PromotionDto) {
  //   return await this.httpService.put<boolean>(`/v1/merchant/promotions/${promotionId}`, updatePromotion)
  // }

  // async destroy(promotionId: string) {
  //   return this.httpService.delete<PromotionDto>(`/v1/merchant/promotions/${promotionId}`)
  // }

  // async published(id: string) {
  //   return await this.httpService.put(`/v1/merchant/promotions/${id}/published`)
  // }

  // async setEnabled(id: string, enabled: boolean) {
  //   if (enabled) {
  //     return await this.httpService.put(`/v1/merchant/promotions/${id}/enable`)
  //   }

  //   return await this.httpService.put(`/v1/merchant/promotions/${id}/disable`)
  // }
}
