import { BaseDto } from "./base.dto"
import { DateOfBirthDto } from "./date-of-birth.dto"
import { OrderDto } from "./order.dto"
import { PagingDto } from "./paging.dto"
import { TenantDto } from "./tenant.dto"
import { UserDto } from "./user.dto"
import * as _ from 'lodash'

export class CreateTenantVoucherDto {
  // khmerTitle: string: string
  enTitle: string
  amount: number
  minOrder: number
  discountType: string
  startDate?: DateOfBirthDto
  endDate?: DateOfBirthDto
  limitUsage?: number

  static create() {
    return new CreateTenantVoucherDto()
  }
}

export class FindTenantVoucherDto extends PagingDto {
  status?: string
  searchText?: string
}

export class TenantVoucherDto extends BaseDto {
  // khmerTitle: string: string
  enTitle: string
  amount: number
  discountType: string
  endDate: Date
  limitUsage: number
  status: string
  voucherCode: string
  tenant: TenantDto

  static create() {
    return new TenantVoucherDto()
  }
}

export class TenantVoucherCampaignDetailDto extends BaseDto {
  type: string
  discount: number
  discountType: string
  validity: number
  titleEn: string
  titleKm: string
  descriptionEn: string
  descriptionKm: string
  termConditionKm: string
  termConditionEn: string
  enabled: boolean
  minOrder: number
  maxOrder: number

  static create() {
    return _.assign<TenantVoucherCampaignDetailDto>({
      type: null,
      discount: '',
      discountType: 'percentage',
      validity: 0,
      titleEn: '',
      titleKm: '',
      descriptionEn: '',
      descriptionKm: '',
      termConditionEn: '',
      termConditionKm: '',
      enabled: false,
      minOrder: 0,
      maxOrder: 0
    })
  }
}

export class UpdateTenantVoucherCampaignDto {
  discount?: number
  discountType?: string
  validity?: number
  titleEn?: string
  titleKm?: string
  descriptionEn?: string
  descriptionKm?: string
  enabled?: boolean
  minOrder?: number
  maxOrder?: number
}

export class TenantVoucherHistoryDto extends BaseDto {
  owner: UserDto
  total: number
  voucherTotal: number
  voucherDiscountAmount: number
  voucherDiscountType: string
  tenantOrder: OrderDto
  tenantVoucher: TenantVoucherDto

  static create() {
    return new TenantVoucherHistoryDto()
  }
}

export enum DiscountStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  EXPIRED = 'expired'
}