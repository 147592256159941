import { Injectable } from '@angular/core'
import { FindPageDto, PageDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'
import { PageSlug } from '../../+merchant/+page/page.component.constant'

@Injectable()
export class pageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPageDto: FindPageDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/merchant/v1/pages', findPageDto)
  }

  async findOne(pageId: string) {
    return await this.httpService.get<PageDto>(`/merchant/v1/pages/${pageId}`)
  }

  async findSlug(slug: PageSlug) {
    return await this.httpService.get<PageDto>(`/merchant/v1/pages/slug`, { slug })
  }

  async updateImage(pageId: string, imageId: string) {
    return await this.httpService.put(`/merchant/v1/pages/${pageId}/image`, { "fileId": imageId })
  }

  async update(pageId: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/merchant/v1/pages/${pageId}`, updatePageDto)
  }
}
