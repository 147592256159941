import { Injectable } from "@angular/core"

import { PageResultDto, BannerDto, FindBannerDto, CreateBannerDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset, type }: FindBannerDto) {
    return await this.httpService.get<PageResultDto<BannerDto>>('/merchant/v1/banners', { limit, offset, type })
  }

  async update(id: string, updateBannerOnboardDto: CreateBannerDto) {
    return await this.httpService.put<Boolean>(`/merchant/v1/banners/${id}`, updateBannerOnboardDto)
  }

  async create(createBannerOnboardDto: CreateBannerDto) {
    return await this.httpService.post<Boolean>(`/merchant/v1/banners`, createBannerOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/banners/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/merchant/v1/banners/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/merchant/v1/banners/${id}/disable`)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/merchant/v1/banners/sort-order`, { ids })
  }
}