import { BaseDto } from "./base.dto"

import { DateOfBirthDto } from "./date-of-birth.dto"
import { PagingDto } from "./paging.dto"
import { ProductDiscountItemsDto, ProductDto } from "./product.dto"
import { TenantDto } from "./tenant.dto"
import * as _ from 'lodash'
import { TranslationDto } from "./translation.dto"
import { FileDto } from "./file.dto"

export class FindProductDiscountTenantDto extends PagingDto {
  status?: string
}

export class ProductDiscountTenantDto extends BaseDto {
  tenant: TenantDto
  title: string
  isSortOrder: boolean
  discount: number
  discountType: string
  expiredAt: Date
}

export class FindProductDiscountDto extends PagingDto {
  status?: string
}

export class AdminProductDiscountDto extends BaseDto {
  title: _.Dictionary< string>
  startedAt: Date
  expiredAt: Date
  discount: number
  discountType: string
  enabled: boolean
  tenant: TenantDto
  status: string
  url: string
  items: ProductDto[]

  static create() {
    return new AdminProductDiscountDto()
  }
}

export class ProductDiscountDto extends BaseDto {
  title: _.Dictionary<string>
  startedAt: string
  expiredAt: string
  discount: number
  discountType: string
  enabled: boolean
  tenant: TenantDto
  status: string
  url: string
  items: ProductDiscountItemsDto[]

  static create() {
    return _.assign(new ProductDiscountDto(), {
      title: TranslationDto.create(),
      startedAt: null,
      expiredAt: null,
      discount: 0,
      discountType: null,
      enabled: null,
      tenant: TenantDto.create(),
      status: null,
      url: null,
      items: ProductDiscountItemsDto.create()
    })
  }
}

export class CreateProductDiscountDto {
  title: _.Dictionary<string>
  startedAt?: string
  expiredAt?: string
  discount?: number
  discountType?: string
}

export class UpdateProductDiscountDto {
  title?: _.Dictionary<string>
  startedAt?: string
  expiredAt?: string
  discount?: number
  discountType?: string
  enabled?: boolean
}

export class CreateProductDiscountItemDto {
  productDiscountId: string
  productId: string
}

export class ProductDiscountItemDto {
  id: string
  sku: string
  name: TranslationDto
  salePrice: number
  enabled: boolean
  adminEnabled: boolean
  stockQuantity: number
  thumbnail?: FileDto
  colorThumbnail?: FileDto
  productAttributes: any
  published: boolean
  allowSelection: boolean
  lowStockAlertQuantity?: number
  specifications?: any
  price: number
  tenant: any
  type: string
  stock: string
  rating: number
  numberOfReviews: number
  weightKg: number
  badgeType?: string
  productAlreadyExist: boolean 

  static create(attributes: any) {
    return _.assign<ProductDto>({
      id: null,
      sku: null,
      name: TranslationDto.create(),
      salePrice: 0,
      stockQuantity: 0,
      lowStockAlertQuantity: 0,
      enabled: true,
      adminEnabled: true,
      thumbnail: null,
      colorThumbnail: null,
      productAttributes: attributes,
      published: false,
      weightKg: null,
      badgeType: null,
      allowSelection: false,
      specifications: [],
      productAlreadyExist: false
    })
  }
}
